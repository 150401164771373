import React from "react"
import { CardDeck } from "react-bootstrap"

import PerformanceCard from "../../components/Performances/PerformanceCard"

const PerformanceGroup = ({ group }) => {
  return (
    <CardDeck className="mt-5">
      {group.map((card, idx) => {
        return (
          <PerformanceCard
            key={idx}
            image={card.image}
            alt={card.alt}
            title={card.title}
            text={card.text}
          />
        )
      })}
    </CardDeck>
  )
}

export default PerformanceGroup
