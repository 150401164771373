import React from "react"
import { Container } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser, isMobile } from "react-device-detect"
import styled from "styled-components"

import StyledHero from "../StyledHero"
import Title from "../Title"

const getImages = graphql`
  query {
    contact: file(relativePath: { eq: "contact.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Contact = () => {
  const data = useStaticQuery(getImages)
  return (
    <StyledSection id="contact">
      <div className="hero-container">
        <StyledHero img={data.contact.childImageSharp.fluid} />
        {isMobile && (
          <div className="centered">
            <Title mobile title="Kontakt" />
          </div>
        )}
      </div>
      <Container className="py-5">
        <div>
          {isBrowser && (
            <>
              <Title title="Kontakt" />
              <hr />
            </>
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <div className="mt-2">
            <h6>Telefon</h6>
            Telefon für Anfragen: 0871 206 572 60
            <br />
            24h Notfallnummer: 0170 76 333 53
          </div>
          <div className="mt-2">
            <h6>Email</h6>
            <a href="mailto:hausmeister@salservicegmbh.de">
              hausmeister@salservicegmbh.de
            </a>
          </div>
          <div className="mt-2">
            <h6>Adresse</h6>
            SAL Service GmbH
            <br />
            Südallee, Frachtmodul D
            <br />
            85356 München-Flughafen
          </div>
        </div>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .hero-container {
    position: relative;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default Contact
