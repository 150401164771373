import React from "react"
import { Container } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"

import links from "../../constants/links"

import OutlineButton from "../OutlineButton"

const AboutMe = () => {
  return (
    <div id="info">
      <Container className="py-5">
        <div className="d-flex flex-column flex-sm-row justify-content-around">
          <div className="col-sm-9 content-box my-auto">
            <h6>Über uns</h6>
            <p>
              Was 2001 als reines Industriereinigungsunternehmen gestartet
              hatte, ist heute mittlerweile in fast allen Gewerken rund um die
              Wertschöpfungskette von Immobilien zu finden. Als inhabergeführtes
              Unternehmen stehen wir für Qualität und unser Wort.
            </p>
            <OutlineButton
              title="Zu den Leistungen"
              onClick={() => scrollTo(links[1].scrollTo)}
            />
          </div>
          <div className="col-sm-3 ml-sm-5 mt-5 mt-sm-0">
            <h6>Immer erreichbar</h6>
            <p>
              Telefon für Anfragen:
              <br />
              0871 206 572 60
              <br />
              24h Notfallnummer:
              <br />
              0170 76 33353
              <br />
              Email:{" "}
              <a href="mailto:hausmeister@salservicegmbh.de">
                hausmeister@salservicegmbh.de
              </a>
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AboutMe
