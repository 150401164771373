import React from "react"
import { Container } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser, isMobile } from "react-device-detect"
import styled from "styled-components"

import links from "../../constants/links"

import StyledHero from "../StyledHero"
import Title from "../Title"
import OutlineButton from "../OutlineButton"

const getImages = graphql`
  query {
    haus: file(relativePath: { eq: "aboutus.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Aboutus = () => {
  const data = useStaticQuery(getImages)
  return (
    <StyledSection id="aboutus">
      <div className="hero-container">
        <StyledHero img={data.haus.childImageSharp.fluid} />
        {isMobile && (
          <div className="centered">
            <Title mobile title="Über uns" />
          </div>
        )}
      </div>
      <Container className="py-5">
        <div>
          {isBrowser && (
            <>
              <Title title="Über uns" />
              <hr />
            </>
          )}
        </div>
        <div className="mb-5 mt-lg-5">
          <h6>Flexibel und zuverlässig</h6>
          <p>
            Unser vorgelagertes Ziel ist es, Hausverwalter und Eigentümer
            spürbar zu entlasten und uns stets auf die individuellen
            Anforderungen in Ihrer Wohnanlage einzustellen. Wir arbeiten gut und
            gerne mit ortsansässigen Handwerkern zusammen. Preisbewusstsein bei
            hoher Produktqualität und Arbeitsleistung ist für uns
            selbstverständlich.
            <br />
            <br />
            Deshalb besteht unser Team ausschließlich aus praxiserprobten
            Gebäudereinigungsfachkräften, Gärtnermeistern, gelernten Handwerkern
            sowie einer starken Verwaltung.
          </p>
        </div>
        <div className="mt-5">
          <h6>Interessiert?</h6>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div className="mt-3">
              <p>Gerne senden wir Ihnen eine Referenzliste zu:</p>
              <OutlineButton
                title="Kontakt"
                onClick={() => scrollTo(links[4].scrollTo)}
              />
            </div>
            <div className="mt-3">
              <p>
                Ausführliche Informationen zu unserem Leistungsangebot finden
                Sie hier:
              </p>
              <OutlineButton
                title="Leistungen"
                onClick={() => scrollTo(links[1].scrollTo)}
              />
            </div>
          </div>
        </div>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .hero-container {
    position: relative;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default Aboutus
