import React from "react"
import Card from "react-bootstrap/Card"
import Image from "gatsby-image"

const PerformanceCard = ({ image, alt, title, text }) => {
  return (
    <Card>
      <Image fluid={image} alt={alt} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {text}
      </Card.Body>
    </Card>
  )
}

export default PerformanceCard
