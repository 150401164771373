import React from "react"

export const cleaning = (
  <ul>
    <li>
      Gebäudereinigung (Treppenhaus, Türen, Fahrstühle, Kellerräume, Dachrinnen,
      Müllcontainer etc.)
    </li>
    <li>Gebäudeentrümpelung</li>
    <li>
      Reinigung der Außenanlagen (Zufahrt, Wege, Fußroste, Gullys,
      wasserführende Rinnen, Gehwegabläufe etc.)
    </li>
    <li>Hochdruckreinigung (Tiefgaragen, Mülltonnen)</li>
  </ul>
)

export const gardenCare = (
  <ul>
    <li>(Turnusmäßige) Betreuung von Gärten und Grünflächen</li>
    <li>Pflege von Rasenflächen (mähen, vertikutieren, düngen etc.)</li>
    <li>Unkrautentfernung</li>
    <li>Strauch, Baum- und Heckenschnitt</li>
    <li>Baumfällungen</li>
    <li>Bepflanzung von Blumenkästen, Kübeln, Beeten</li>
    <li>Abmulchen von Pflanzflächen</li>
    <li>Beseitigung von Unrat und Laub aus der Gartenanlage</li>
    <li>Bewässerung der Grünanlagen</li>
    <li>
      Säuberung von Spielplatzanlagen und Sandkasten, Sandauflockerung und
      -austausch, Prüfung der Spielplatzgeräte auf Mängel (Sichtkontrolle)
    </li>
    <li>Pflasterarbeiten</li>
  </ul>
)

export const repair = (
  <ul>
    <li>Rundum-Entrümpelungsservice</li>
    <li>Verlegen/Reparatur von Fußböden (Laminat, Parkett)</li>
    <li>Kleinere Malerarbeiten</li>
    <li>Verlegen von Holzterrassen</li>
  </ul>
)

export const houseTechnique = (
  <ul>
    <li>
      Überprüfung und Wartung der elektrischen Einrichtungen (Klingelanlagen,
      Beleuchtung, Austausch von defekten Lampen etc.)
    </li>
    <li>
      Überprüfung und Wartung der mechanischen Einrichtungen (Türschließer,
      Zylinder etc.)
    </li>
    <li>
      Überprüfung der Heizungsanlagen (Brennerkontrolle, Wasser auffüllen etc.)
    </li>
    <li>Überprüfung von Aufzügen auf Funktionalität</li>
  </ul>
)

export const winterServices = (
  <ul>
    <li>
      Vorbereitungen auf den Winter (Entleerung der Wasserleitungen an
      Außenzapfstellen etc.)
    </li>
    <li>Schneeräumen und Streudienst</li>
    <li>Entsorgung des ausgebrachten Streuguts</li>
  </ul>
)

export const emergencyService = (
  <span>
    Nichts ist schlimmer als eine defekte Heizung im Winter. In Notsituationen
    sind wir zu jeder Zeit für Sie erreichbar – 24 Stunden am Tag an 7 Tagen in
    der Woche.
  </span>
)

export const management = (
  <span>
    Ihr Hausmeister braucht Urlaub oder ist erkrankt? Der Hausmeisterservice SAL
    übernimmt auch kurzfristig die Betreuung Ihrer Wohnanlage.
  </span>
)
