import React from "react"
import { Container } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser, isMobile } from "react-device-detect"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

import links from "../../constants/links"

import PerformanceGroup from "../../components/Performances/PerformanceGroup"
import {
  cleaning,
  gardenCare,
  repair,
  houseTechnique,
  winterServices,
  emergencyService,
  management,
} from "./content"

import StyledHero from "../StyledHero"
import Title from "../Title"
import OutlineButton from "../OutlineButton"

const getImages = graphql`
  query {
    performance: file(relativePath: { eq: "performance.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    performanceMobile: file(relativePath: { eq: "heyer-performance.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cleaning: file(relativePath: { eq: "clean.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    garden: file(relativePath: { eq: "garden.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    repair: file(relativePath: { eq: "repair.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    technic: file(relativePath: { eq: "technic.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    winter: file(relativePath: { eq: "winter.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    not: file(relativePath: { eq: "not.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vertretung: file(relativePath: { eq: "vertretung.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Performances = () => {
  const images = useStaticQuery(getImages)
  const cardGroups = [
    {
      cardgroup1: [
        {
          image: images.cleaning.childImageSharp.fluid,
          alt: "Bodenreinigung",
          title: "Reinigung",
          text: cleaning,
        },
        {
          image: images.garden.childImageSharp.fluid,
          alt: "GartenPflege",
          title: "Gartenpflege",
          text: gardenCare,
        },
        {
          image: images.repair.childImageSharp.fluid,
          alt: "Boden verlegen",
          title: "Kleinreparaturen / Renovierungen / Entrümpelung",
          text: repair,
        },
      ],
      cardgroup2: [
        {
          image: images.technic.childImageSharp.fluid,
          alt: "Haustechnik",
          title: "Haustechnik",
          text: houseTechnique,
        },
        {
          image: images.winter.childImageSharp.fluid,
          alt: "Winterdienst",
          title: "Winterdienst",
          text: winterServices,
        },
      ],
      cardgroup3: [
        {
          image: images.not.childImageSharp.fluid,
          alt: "Notdienst",
          title: "Notdienst",
          text: emergencyService,
        },
        {
          image: images.vertretung.childImageSharp.fluid,
          alt: "Hausmeistervertretung",
          title: "Hausmeistervertretung",
          text: management,
        },
      ],
    },
  ]

  const getImage = () => {
    if (isBrowser) {
      return images.performance.childImageSharp.fluid
    } else {
      return images.performanceMobile.childImageSharp.fluid
    }
  }

  return (
    <StyledSection id="performances">
      <div className="hero-container">
        <StyledHero img={getImage()} />
        {isMobile && (
          <div className="centered">
            <Title mobile title="Unsere Leistungen" />
          </div>
        )}
      </div>
      <Container className="py-5">
        <div>
          {isBrowser && (
            <>
              <Title title="Unsere Leistungen" />
              <hr />
            </>
          )}
        </div>
        <div className="mb-5 mt-lg-5">
          <p>
            Reinigungsarbeiten, Gartenpflege, Renovierungsarbeiten und die
            Wartung der Haustechnik gehören ebenso zu unserem Leistungsspektrum
            wie Winter- und Notdienste.
            <br />
            <br />
            Für jegliche Einsatzzwecke verfügen wir über entsprechende Maschinen
            - angefangen von Großflächenmähern bis hin zu einem Fuhrpark für den
            Winterdienst. Dies ermöglicht es uns, flexibel auf die individuellen
            Anforderungen in Ihrer Wohnanlage zu reagieren. In Notfällen sind
            wir zügig vor Ort.
          </p>
        </div>
        <h6>Unsere Leistungen im Detail</h6>
        <PerformanceGroup group={cardGroups[0].cardgroup1} />
        <PerformanceGroup group={cardGroups[0].cardgroup2} />
        <PerformanceGroup group={cardGroups[0].cardgroup3} />
        <div className="mt-5">
          <h6>Interessiert?</h6>
          <p>
            Dann sprechen Sie uns an. Gerne erstellen wir Ihnen ein Angebot und
            beantworten offene Fragen:
          </p>

          <OutlineButton
            title="Kontakt"
            onClick={() => scrollTo(links[4].scrollTo)}
          />
        </div>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .hero-container {
    position: relative;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default Performances
