import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import Gallery from "@browniebroke/gatsby-image-gallery"

const getData = graphql`
  query ImagesForGallery {
    allFile(filter: { relativeDirectory: { eq: "Projects" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 1000
              height: 1000
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

const GalleryImages = () => {
  const data = useStaticQuery(getData)
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  // `images` is an array of objects with `thumb` and `full`
  return <Gallery images={images} />
}

export default GalleryImages
