import React from "react"
import { Container } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser, isMobile } from "react-device-detect"
import styled from "styled-components"

import StyledHero from "../StyledHero"
import Title from "../Title"
import GalleryImages from "./GalleryImages"

const getImages = graphql`
  query {
    contact: file(relativePath: { eq: "projekte.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Projekts = () => {
  const data = useStaticQuery(getImages)
  return (
    <StyledSection id="projects">
      <div className="hero-container">
        <StyledHero img={data.contact.childImageSharp.fluid} />
        {isMobile && (
          <div className="centered">
            <Title mobile title="Projekte" />
          </div>
        )}
      </div>
      <Container className="py-5">
        <div>
          {isBrowser && (
            <>
              <Title title="Projekte" />
              <hr />
            </>
          )}
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <div className="mt-2">
            <GalleryImages />
          </div>
        </div>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .hero-container {
    position: relative;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    cursor: pointer;
  }
`

export default Projekts
