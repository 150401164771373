import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser } from "react-device-detect"

import StyledHero from "../../components/StyledHero"
import Banner from "../../components/Banner"

const getLogo = graphql`
  query {
    thwlogo: file(relativePath: { eq: "heftig-logo.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Intro = () => {
  const data = useStaticQuery(getLogo)
  const browserTitle = "Hausmeisterservice - SAL Service GmbH"
  const mobileTitle = "Hausmeisterservice"
  const browserSubTitle = "Ihr Partner für professionelles Facility Management"
  const mobileSubTitle = "SAL Service GmbH"
  return (
    <section id="home">
      <StyledHero home>
        <Banner
          image={
            <Image
              fluid={data.thwlogo.childImageSharp.fluid}
              alt="Heftige Webseiten logo"
            />
          }
          title={isBrowser ? browserTitle : mobileTitle}
          subtitle={isBrowser ? browserSubTitle : mobileSubTitle}
        ></Banner>
      </StyledHero>
    </section>
  )
}

export default Intro
